@font-face {
    font-family: BeautySchoolDropout;
    src: url('./assets/fonts/BeautySchoolDropout.eot');
    src: url('./assets/fonts/BeautySchoolDropout.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/BeautySchoolDropout.woff2') format('woff2'),
        url('./assets/fonts/BeautySchoolDropout.woff') format('woff'),
        url('./assets/fonts/BeautySchoolDropout.ttf') format('truetype'),
        url('./assets/fonts/BeautySchoolDropout.svg#BeautySchoolDropout') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: BeautySchoolDropoutII;
    src: url('./assets/fonts/BeautySchoolDropoutII.eot');
    src: url('./assets/fonts/BeautySchoolDropoutII.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/BeautySchoolDropoutII.woff2') format('woff2'),
        url('./assets/fonts/BeautySchoolDropoutII.woff') format('woff'),
        url('./assets/fonts/BeautySchoolDropoutII.ttf') format('truetype'),
        url('./assets/fonts/BeautySchoolDropoutII.svg#BeautySchoolDropoutII') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Reey;
    src: url('./assets/fonts/Reey-Regular.eot');
    src: url('./assets/fonts/Reey-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Reey-Regular.woff2') format('woff2'),
        url('./assets/fonts/Reey-Regular.woff') format('woff'),
        url('./assets/fonts/Reey-Regular.ttf') format('truetype'),
        url('./assets/fonts/Reey-Regular.svg#Reey-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #1b140d;
}

::-moz-selection {
    background: #1b140d;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: #1b140d;
    color: #fff;
    text-shadow: none;
}

body {
    margin: 0;
    background: #96725b url('assets/background.jpg');
    background-size: cover;
    background-attachment: fixed;
}

#app {
    font-family: Helvetica, Arial, sans-serif;
    margin: 0 auto;
    min-height: 100vh;
}

h1 {
    font: 1.5rem Helvetica, Arial, sans-serif;
    color: #a26737;
    margin: 0;
    cursor: default;
}

a {
    color: #1b140d;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

ul {
    list-style: none;
    margin: 0;
}

li {
    padding: .1rem 0;
}

img {
    max-width: 100%;
}

footer {
    margin: 1.5rem 0 0;
    text-align: center;
    padding: 1rem;

    h2 {
        color: #fff;
        text-transform: uppercase;
    }

    ul {
        padding: 0;

        li {
            display: inline-block;

            &:last-child {
                padding-left: 1.5rem;
            }
        }
    }
}

@media screen and (max-width: 64rem) {
    html, body {
        overflow-x: hidden;
    }
}

@media screen and (min-width: 48rem) {
    .hidden-desktop {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 48rem) {
    .hidden-mobile {
        display: none;
        visibility: hidden;
    }

    h1 {
        font-size: 1rem;
    }
}
